* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; 
  font-family: 'Roboto';
}

body {
  font-size: 1.3rem;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  background-color: #151516;
  background: #151516;
  min-height: 100vh;
}


