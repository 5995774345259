.lexiqueTitle {
    height: 100%;
}

.lexiqueContainer {
    height: 100%;
}

.inputSearch {
    display: flex;
    justify-content: center;
}
