.news-title {
    font-size: 1rem;
    padding: 25px;
    color: #212121;  
}

.news-title a {
    color: #212121
}

.source-times {
    color: #212121;
    font-size: 0.7rem;
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    padding: 25px;
}